<template>
  <v-container class="strategy py-5" fluid style="background: #eee;height:100%;">

<div style="max-width:1000px;margin:0 auto;">
<keep-alive include="symbols">
<WidgetSymbolTab :symbols="$store.state.systemOption.symbols"/>
</keep-alive>


<div class="mt-5"
      v-for="(item, index) in $store.state.systemOption.symbols" :key="item.id">
    <v-row class="px-" v-if="index == $store.state.symbol_focus">
      <v-col cols="6" >
<keep-alive include="side,symbol,bot_id,index">
        <WidgetBotOption  :side="'buy'" :symbol="item" :bot_id="item+'-buy'" :index="index"/>
</keep-alive>
      </v-col>
      <v-col cols="6">
<keep-alive include="side,symbol,bot_id,index">
        <WidgetBotOption  :side="'sell'" :symbol="item" :bot_id="item+'-sell'" :index="index"/>
</keep-alive>
      </v-col>
    </v-row>
</div>

</div>



  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
//import HelloWorld from "../components/HelloWorld.vue";

export default Vue.extend({
  name: "Strategy",

  components: {
    //HelloWorld,
  },
  data: () => ({
  }),
  computed: {},
});
</script>
